const state = {
    period: null
};

const getters = {
    period: state => {
        return state.period;
    }
};

const actions = {
    setPeriod({ commit }, period) {
        commit('setPeriod', period);
    }
};

const mutations = {
    setPeriod(state, period) {
        state.period = period;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
