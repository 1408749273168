<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <line y1="14" transform="translate(3 12) rotate(-90)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"></line>
        <path
            d="M626.189,1181.731l-3-3-3,3"
            transform="translate(-1175.731 635.189) rotate(-90)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
        <path
            d="M619.189,1185.731h-3a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2h14a2,2,0,0,0,2-2v-7a2,2,0,0,0-2-2h-3"
            transform="translate(-1175.731 635.189) rotate(-90)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
        <rect width="24" height="24" transform="translate(0 24) rotate(-90)" fill="none"></rect>
    </svg>
</template>

<script>
export default {
    name: 'LogoffIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
