import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import social from './modules/social';
import filters from './modules/filters';
import operational from './modules/operational';
import acl from './modules/acl';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,
        social,
        filters,
        operational,
        acl
    },
    strict: false
});

export default store;
