<template>
    <svg id="icon_compras" data-name="icon compras" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Retângulo_4653" data-name="Retângulo 4653" width="24" height="24" fill="none" />
        <g id="Grupo_16186" data-name="Grupo 16186" transform="translate(-1283.619 -447.73)">
            <path
                id="Caminho_5107"
                data-name="Caminho 5107"
                d="M1301.683,454.731h-12.128a1,1,0,0,0-1,.933l-.8,11.934a2,2,0,0,0,2,2.133h11.724a2,2,0,0,0,2-2.133l-.8-11.934A1,1,0,0,0,1301.683,454.731Z"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                id="Caminho_5108"
                data-name="Caminho 5108"
                d="M1298.62,456.731v-4h0a3,3,0,0,0-3-3h0a3,3,0,0,0-3,3h0v4"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'UserIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
