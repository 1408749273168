<template>
    <div id="app">
        <div class="font-display">
            <Header />
            <div class="min-h-screen pb-8 bg-gray-100 flex">
                <Sidebar class="w-56 border-r flex-none" />
                <div class="flex-1 pl-6 pr-6 bg-gray-100 min-w-0 absolute top-20 left-60 right-0 overflow-auto font-sans">
                    <router-view />

                    <Footer />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Sidebar from '@/components/Sidebar.vue';
export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Sidebar
    },
    data() {
        return {
            toastVisible: false,
            toastData: {
                msg: '',
                style: '',
                duration: 2000
            }
        };
    },
    methods: {}
};
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
