<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
        <path d="M89.487,172.768h-24v-24h24Z" transform="translate(-65.487 -148.767)" fill="none"></path>
        <path
            d="M80.368,271.9a3.11,3.11,0,1,0,6.22,0"
            transform="translate(-71.156 -253.788)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
        <path
            d="M85.9,262.4H72.632a1.226,1.226,0,0,1-.837-2.123l.493-.459a2.264,2.264,0,0,0,.719-1.655v-4.6a6.258,6.258,0,0,1,6.258-6.258h0a6.258,6.258,0,0,1,6.258,6.258v4.6a2.263,2.263,0,0,0,.719,1.655l.493.459A1.226,1.226,0,0,1,85.9,262.4Z"
            transform="translate(-66.985 -244.317)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
    </svg>
</template>

<script>
export default {
    name: 'NotificationIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
