<template>
    <svg id="icon_not_pagamento" data-name="icon not pagamento" xmlns="http://www.w3.org/2000/svg" width="24" height="24.001" viewBox="0 0 24 24.001">
        <circle id="path" cx="10.399" cy="10.399" r="10.399" transform="translate(1.677 1.51)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
        <path id="area" d="M334.126,327.067h-24v-24h24Z" transform="translate(-310.126 -303.066)" fill="none" />
        <g id="Grupo_15056" data-name="Grupo 15056" transform="translate(-1115.619 -2743.719)">
            <line
                id="Linha_808"
                data-name="Linha 808"
                y2="1.25"
                transform="translate(1127.619 2750.719)"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </g>
        <g id="Grupo_15057" data-name="Grupo 15057" transform="translate(-1115.619 -2743.719)">
            <line
                id="Linha_809"
                data-name="Linha 809"
                y1="1.246"
                transform="translate(1127.619 2759.473)"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </g>
        <g id="Grupo_15058" data-name="Grupo 15058" transform="translate(-1115.619 -2743.719)">
            <path
                id="Caminho_5039"
                data-name="Caminho 5039"
                d="M1130.106,2753.719a1.868,1.868,0,0,0-1.862-1.75h-1.37a1.755,1.755,0,0,0-.427,3.458l2.344.588a1.755,1.755,0,0,1-.427,3.458h-1.37a1.87,1.87,0,0,1-1.863-1.754"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'UserIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
