import Vue from 'vue';
import VueRouter from 'vue-router';
import { TokenService } from '../services/storage.service';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const verifyRoles = (roles) => {
    if (roles) {
        const aclRoles = localStorage.acl ? JSON.parse(localStorage.acl) : null;
        const userRoles = aclRoles.roles.map(role => role.name);
        return userRoles.some(role => roles.includes(role));
    }

    return true;
}

router.beforeEach((to, from, next) => {
    const loggedIn = !!TokenService.getToken();

    if (to.name === 'auth' || to.name === 'default') {
        if (loggedIn) {
            next({ name: 'home' });
        } else {
            next({ name: 'login' });
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (loggedIn) {

            const hasRoles = verifyRoles(to.meta.roles);
            if(hasRoles) {
                next();
            } 
            
        } else {
            next({
                name: 'login'
            });
        }
    } else {
        next();
    }
});
export default router;
