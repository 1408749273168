<template>
    <svg id="icon_kivid" data-name="icon kivid" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_14417" data-name="Grupo 14417" transform="translate(-611.189 -8511.744)">
            <g id="Grupo_14422" data-name="Grupo 14422">
                <g id="Grupo_14421" data-name="Grupo 14421">
                    <g id="Grupo_14417-2" data-name="Grupo 14417">
                        <g id="Grupo_14416" data-name="Grupo 14416">
                            <path
                                id="Caminho_4845"
                                data-name="Caminho 4845"
                                d="M618.189,8532.744v-15h-4a1,1,0,0,0-1,1v14"
                                fill="none"
                                stroke="#776de6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                            />
                            <path
                                id="Caminho_4846"
                                data-name="Caminho 4846"
                                d="M633.189,8532.744v-14a1,1,0,0,0-1-1h-4v15"
                                fill="none"
                                stroke="#776de6"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                            />
                        </g>
                    </g>
                    <path id="Caminho_4847" data-name="Caminho 4847" d="M634.189,8532.744" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <line
                        id="Linha_657"
                        data-name="Linha 657"
                        x2="5"
                        transform="translate(620.689 8529.744)"
                        fill="none"
                        stroke="#776de6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <rect
                        id="Retângulo_4009"
                        data-name="Retângulo 4009"
                        width="3"
                        height="3"
                        transform="translate(621.689 8529.744)"
                        fill="none"
                        stroke="#776de6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <g id="Grupo_14418" data-name="Grupo 14418">
                        <line
                            id="Linha_658"
                            data-name="Linha 658"
                            x2="4"
                            transform="translate(621.189 8526.744)"
                            fill="none"
                            stroke="#776de6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <line
                            id="Linha_659"
                            data-name="Linha 659"
                            x2="4"
                            transform="translate(621.189 8523.744)"
                            fill="none"
                            stroke="#776de6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                    </g>
                    <line
                        id="Linha_660"
                        data-name="Linha 660"
                        x2="2"
                        transform="translate(616.189 8525.244)"
                        fill="none"
                        stroke="#776de6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <g id="Grupo_14419" data-name="Grupo 14419">
                        <line
                            id="Linha_661"
                            data-name="Linha 661"
                            x2="2"
                            transform="translate(616.189 8528.744)"
                            fill="none"
                            stroke="#776de6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <line
                            id="Linha_662"
                            data-name="Linha 662"
                            x2="2"
                            transform="translate(616.189 8521.744)"
                            fill="none"
                            stroke="#776de6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                    </g>
                    <line
                        id="Linha_663"
                        data-name="Linha 663"
                        x1="6"
                        transform="translate(628.189 8532.744)"
                        fill="none"
                        stroke="#776de6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <line
                        id="Linha_664"
                        data-name="Linha 664"
                        x1="6"
                        transform="translate(612.189 8532.744)"
                        fill="none"
                        stroke="#776de6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <line
                        id="Linha_665"
                        data-name="Linha 665"
                        x2="2"
                        transform="translate(628.189 8525.244)"
                        fill="none"
                        stroke="#776de6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <g id="Grupo_14420" data-name="Grupo 14420">
                        <line
                            id="Linha_666"
                            data-name="Linha 666"
                            x2="2"
                            transform="translate(628.189 8528.744)"
                            fill="none"
                            stroke="#776de6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                        <line
                            id="Linha_667"
                            data-name="Linha 667"
                            x2="2"
                            transform="translate(628.189 8521.744)"
                            fill="none"
                            stroke="#776de6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        />
                    </g>
                </g>
                <path id="Caminho_4848" data-name="Caminho 4848" d="M611.189,8511.744h24v24h-24Z" fill="none" />
                <path
                    id="Caminho_4849"
                    data-name="Caminho 4849"
                    d="M618.189,8532.744h10v-16.96a1.021,1.021,0,0,0-1-1.04h-8a1.021,1.021,0,0,0-1,1.04Z"
                    fill="none"
                    stroke="#776de6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </g>
            <line id="Linha_668" data-name="Linha 668" x2="3" transform="translate(621.689 8519.244)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            <line id="Linha_669" data-name="Linha 669" y1="3" transform="translate(623.189 8517.744)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'UserIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
