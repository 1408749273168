<template>
    <div class="fixed top-0 left-0 right-0 z-10 bg-gray-100">
        <div class="mx-auto bg-white px-4 border-b-2 border-gray-100 rounded-b-2xl pb-1">
            <div class="flex items-center justify-between py-4 md:justify-start md:space-x-10">
                <div class="flex justify-start lg:w-0 lg:flex-1">
                    <router-link to="home" class="router-link-active">
                        <span class="sr-only">Kivid</span>
                        <Logo />
                    </router-link>
                </div>
                <div class="-my-2 -mr-2 md:hidden">
                    <button
                        type="button"
                        aria-expanded="false"
                        class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                        <span class="sr-only">Menu</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-6 w-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
                        </svg>
                    </button>
                </div>
                <nav class="hidden space-x-10 md:flex"><div class="relative"></div></nav>
                <div class="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
                    <button
                        type="button"
                        class="text-white bg-kvpurple-500 hover:bg-purple-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl text-sm p-2 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <HelpIcon />
                        <span class="sr-only">Ajuda</span>
                    </button>
                    <button
                        type="button"
                        class="text-white bg-kvpurple-500 hover:bg-purple-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl text-sm p-2 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <NotificationIcon />
                        <span class="sr-only">Notificação</span>
                    </button>
                    <button
                        type="button"
                        class="text-white bg-kvpurple-500 hover:bg-purple-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl text-sm p-2 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <UserIcon stroke-color="#fff" />
                        <span class="sr-only">Usuário</span>
                    </button>
                    <button
                        type="button"
                        @click="logout"
                        class="text-white bg-kvpurple-500 hover:bg-purple-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl text-sm p-2 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        <LogoffIcon />
                        <span class="sr-only">Sair</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Logo from '@/assets/Logo.vue';
import HelpIcon from '@/assets/icons/HelpIcon.vue';
import NotificationIcon from '@/assets/icons/NotificationIcon.vue';
import UserIcon from '@/assets/icons/UserIcon.vue';
import LogoffIcon from '@/assets/icons/LogoffIcon.vue';
export default {
    name: 'Header',
    components: {
        Logo,
        HelpIcon,
        NotificationIcon,
        UserIcon,
        LogoffIcon
    },
    data() {
        return {
            view: {
                atTopOfPage: true,
                upScroll: false
            },
            condensedView: false,
            showMenu: false,
            lastScrollTop: 0
        };
    },
    computed: {
        ...mapGetters('social', ['profileData'])
    },
    methods: {
        ...mapActions('auth', ['logout'])
    }
};
</script>

<style scoped>
.active-tab {
    @apply text-green-500;
}

nav {
    z-index: 10;
}

nav.up-scroll {
    @apply bg-green-400;
    border-bottom: 10px;
    @apply border-green-500;
}

nav.scrolled {
    @apply bg-green-400;
    border-bottom: 10px;
    @apply border-green-500;
}
</style>
