<template>
    <svg id="menu_feed" data-name="menu feed" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_14582" data-name="Grupo 14582" transform="translate(-146.152 -172.865)">
            <rect id="Retângulo_4484" data-name="Retângulo 4484" width="24" height="24" transform="translate(146.152 196.865) rotate(-90)" fill="none" />
            <g id="Grupo_14602" data-name="Grupo 14602" transform="translate(0 -0.473)">
                <rect
                    id="Retângulo_4485"
                    data-name="Retângulo 4485"
                    width="7.2"
                    height="18"
                    rx="2.42"
                    transform="translate(149.152 183.538) rotate(-90)"
                    fill="none"
                    :stroke="strokeColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <rect
                    id="Retângulo_4486"
                    data-name="Retângulo 4486"
                    width="7.2"
                    height="18"
                    rx="2.42"
                    transform="translate(149.152 193.865) rotate(-90)"
                    fill="none"
                    :stroke="strokeColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'FeedIcon',
    props: {
        strokeColor: {
            type: String,
            default: '#776de6'
        }
    },
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
