<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect width="24" height="24" fill="none"></rect>
        <path
            d="M277,11159.927h0a3.028,3.028,0,0,1,3.032-3.029h3.479a3.028,3.028,0,0,1,3.031,3.029h0"
            transform="translate(-269.616 -11142.926)"
            fill="none"
            :stroke="strokeColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
        <path d="M2.9,0A2.9,2.9,0,1,1,0,2.9,2.9,2.9,0,0,1,2.9,0Z" transform="translate(9.168 5.963)" fill="none" :stroke="strokeColor" stroke-linecap="round" stroke-width="1.3"></path>
        <ellipse cx="10" cy="10" rx="10" ry="10" transform="translate(2 2)" stroke-width="1.3" :stroke="strokeColor" stroke-linecap="round" stroke-linejoin="round" fill="none"></ellipse>
    </svg>
</template>

<script>
export default {
    props: {
        strokeColor: {
            type: String,
            default: '#776de6'
        }
    },
    name: 'UserIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
