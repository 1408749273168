<template>
    <svg id="icon_pagamento_dinheiro" data-name="icon pagamento dinheiro" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect id="Retângulo_4553" data-name="Retângulo 4553" width="24" height="24" fill="none" />
        <circle
            id="Elipse_708"
            data-name="Elipse 708"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(9 8.098)"
            fill="none"
            stroke="#776de6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <path
            id="Caminho_5042"
            data-name="Caminho 5042"
            d="M1136.619,3541.7v-7.885a2,2,0,0,0-2-2h-15a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2h8.261"
            transform="translate(-1115.619 -3527.719)"
            fill="none"
            stroke="#776de6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <line id="Linha_810" data-name="Linha 810" x2="2" transform="translate(5 14.098)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
        <line id="Linha_811" data-name="Linha 811" x2="2" transform="translate(16 7.098)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
        <path
            id="Caminho_5043"
            data-name="Caminho 5043"
            d="M1130.993,3542.678a3.475,3.475,0,1,0,3.252,4.933"
            transform="translate(-1115.619 -3527.719)"
            fill="none"
            stroke="#776de6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
        <path
            id="Caminho_5044"
            data-name="Caminho 5044"
            d="M1134.14,3547.621a3.479,3.479,0,1,1,3.479-3.479,3.479,3.479,0,0,1-3.479,3.479"
            transform="translate(-1115.619 -3527.719)"
            fill="none"
            stroke="#776de6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        />
    </svg>
</template>

<script>
export default {
    name: 'MoneyIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
