<template>
    <aside class="p-6 absolute top-20">
        <nav>
            <ul>
                <li v-for="item in menu" :key="item.id">
                    <div
                        v-if="verifyRoles(item.roles)"
                        :class="setButtonClass(item.route)"
                        class="flex max-w-sm p-4 hover:bg-cyan-600 mb-4 border border-gray-200 rounded-2xl dark:bg-gray-800 dark:border-gray-700"
                    >
                        <router-link :to="{ name: item.route }" href="#" class="w-full">
                            <div class="flex pb-1 justify-between">
                                <component :is="item.icon"></component>
                                <svg id="icon_indicate_righ" xmlns="http://www.w3.org/2000/svg" width="24" height="23.999" viewBox="0 0 24 23.999">
                                    <path
                                        id="path"
                                        d="M487.1,543.323l5.836,6.55-5.836,6.55"
                                        transform="translate(-478.02 -537.94)"
                                        fill="none"
                                        :stroke="setSvgClass(item.route)"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="1.5"
                                    ></path>
                                    <path id="area" d="M497.237,558.514h-24v-24h24Z" transform="translate(-473.237 -534.515)" fill="none"></path>
                                </svg>
                            </div>
                            <h5 :class="setTextClass(item.route)" class="leading-4 pt-2 text-sm mr-6 font-semibold dark:text-white">{{ item.name }}</h5>
                        </router-link>
                    </div>
                </li>
            </ul>
        </nav>
    </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FeedIcon from '@/assets/icons/FeedIcon.vue';
import HospitalIcon from '@/assets/icons/HospitalIcon.vue';
import TermsIcon from '@/assets/icons/TermsIcon.vue';
import UserIcon from '@/assets/icons/UserIcon.vue';
import ShoppingIcon from '@/assets/icons/ShoppingIcon.vue';
import MoneyIcon from '@/assets/icons/MoneyIcon.vue';
import PaymentIcon from '@/assets/icons/PaymentIcon.vue';

export default {
    name: 'Sidebar',
    data() {
        return {
            menu: [
                { id: 1, name: 'Resumo e atividades', icon: FeedIcon, route: 'resume', roles: ['Administrador'] },
                {
                    id: 2,
                    name: 'Vendas e informações',
                    icon: MoneyIcon,
                    route: 'sales',
                    roles: ['AdministrSupador']
                },
                {
                    id: 3,
                    name: 'Usuários e audiência',
                    icon: UserIcon,
                    route: 'users',
                    roles: ['Administrador'] 
                },
                {
                    id: 4,
                    name: 'Financeiro e dados',
                    icon: PaymentIcon,
                    route: 'finantial',
                    roles: ['Administrador']
                },
                {
                    id: 5,
                    name: 'Usuários',
                    icon: TermsIcon,
                    route: 'operational.users',
                    roles: ['Administrador', 'kivid-suporte']
                },
                {
                    id: 6,
                    name: 'Marketing e comunicação',
                    icon: ShoppingIcon,
                    route: 'marketing',
                    roles: ['Administrador']
                },
                {
                    id: 7,
                    name: 'Rede Kivid e afiliados',
                    icon: HospitalIcon,
                    route: 'network',
                    roles: ['Administrador']
                },
                {
                    id: 8,
                    name: 'Logs',
                    icon: TermsIcon,
                    route: 'operational.logs',
                    roles: ['Administrador', 'kivid-suporte']
                }
            ]
        };
    },
    computed: {
        ...mapGetters('social', ['profileData']),
        ...mapGetters('acl', ['acl'])
    },
    methods: {
        ...mapActions('auth', ['logout']),
        verifyRoles(roles) {
            if (roles) {
                const userRoles = this.acl.roles.map(role => role.name);
                return roles.some(role => userRoles.includes(role));
            }
            return true;
        },
        setButtonClass(route) {
            return {
                'bg-kvpurple-500': this.$route.name === route,
                'bg-white': this.$route.name !== route
            };
        },
        setTextClass(route) {
            return {
                'text-white': this.$route.name === route,
                'text-gray-600': this.$route.name !== route
            };
        },
        setSvgClass(route) {
            return this.$route.name === route ? '#fff' : '#4b565b';
        }
    }
};
</script>

<style scoped>
/* .active-tab {
    @apply text-green-500;
}

nav {
    z-index: 10;
}

nav.up-scroll {
    @apply bg-green-400;
    border-bottom: 10px;
    @apply border-green-500;
}

nav.scrolled {
    @apply bg-green-400;
    border-bottom: 10px;
    @apply border-green-500;
} */
</style>
