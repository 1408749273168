<template>
    <svg id="icon_termos" data-name="icon termos" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="Grupo_14658" data-name="Grupo 14658" transform="translate(-1451.619 -6551.744)">
            <rect
                id="Retângulo_4505"
                data-name="Retângulo 4505"
                width="12"
                height="14"
                rx="2"
                transform="translate(1455.619 6558.744)"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                id="Caminho_4954"
                data-name="Caminho 4954"
                d="M1467.619,6568.744h2a2,2,0,0,0,2-2v-10a2,2,0,0,0-2-2h-8a2,2,0,0,0-2,2v2"
                fill="none"
                stroke="#776de6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <line id="Linha_716" data-name="Linha 716" x2="4" transform="translate(1459.619 6563.744)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            <line id="Linha_717" data-name="Linha 717" x2="4" transform="translate(1459.619 6567.744)" fill="none" stroke="#776de6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
        </g>
        <rect id="Retângulo_4506" data-name="Retângulo 4506" width="24" height="24" fill="none" />
    </svg>
</template>

<script>
export default {
    name: 'TermsIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
