<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M611.189,7839.744h24v24h-24Z" transform="translate(-611.189 -7839.744)" fill="none"></path>
        <path
            d="M616.186,7858.564a9.82,9.82,0,0,1-2-5.924,9.925,9.925,0,0,1,9.9-9.9"
            transform="translate(-612.085 -7840.64)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
            stroke-dasharray="0 3.3"
        ></path>
        <path
            d="M616.005,7858.564a9.887,9.887,0,1,0,7.9-15.82"
            transform="translate(-611.904 -7840.64)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
        <path
            d="M623.2,7855.578a.192.192,0,1,0,.193.192.192.192,0,0,0-.193-.192"
            transform="translate(-611.206 -7839.362)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
        <path
            d="M623.38,7853.383v-.265a1.982,1.982,0,0,1,1.071-1.693,1.943,1.943,0,0,0,1.048-1.659,2.118,2.118,0,0,0-4.235,0"
            transform="translate(-611.381 -7840.152)"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
        ></path>
    </svg>
</template>

<script>
export default {
    name: 'HelpIcon',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
