<template>
    <svg data-v-cdbe3ac4="" id="logo-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="143.488" height="39.592" viewBox="0 0 143.488 39.592">
        <defs data-v-cdbe3ac4="">
            <linearGradient data-v-cdbe3ac4="" id="a" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop data-v-cdbe3ac4="" offset="0" stop-color="#f27f52"></stop>
                <stop data-v-cdbe3ac4="" offset="1" stop-color="#fbc442"></stop>
            </linearGradient>
            <linearGradient data-v-cdbe3ac4="" id="b" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop data-v-cdbe3ac4="" offset="0" stop-color="#7812b0"></stop>
                <stop data-v-cdbe3ac4="" offset="1" stop-color="#f1006b"></stop>
            </linearGradient>
            <linearGradient data-v-cdbe3ac4="" id="c" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                <stop data-v-cdbe3ac4="" offset="0" stop-color="#f1006b"></stop>
                <stop data-v-cdbe3ac4="" offset="1" stop-color="#f78a57"></stop>
            </linearGradient>
        </defs>
        <path
            data-v-cdbe3ac4=""
            d="M941.175,574.249a4.353,4.353,0,0,0-.3.368,2.594,2.594,0,0,0-.213.4,1.931,1.931,0,0,0-.131.451,2.055,2.055,0,0,0-.048.462,2.373,2.373,0,0,0,.688,1.672,2.373,2.373,0,1,0,3.356-3.356A2.468,2.468,0,0,0,941.175,574.249Zm-13.757,16.835,7.28-6.052a2.371,2.371,0,1,0-3.031-3.648l-10.7,8.9V575.93a2.371,2.371,0,1,0-4.743,0v24.258a2.371,2.371,0,1,0,4.743,0v-3.74l2.79-2.32,7.746,7.737a2.372,2.372,0,0,0,3.352-3.356ZM968,581.191a2.37,2.37,0,0,0-3.118,1.236L959.8,594.189l-5.072-11.911a2.372,2.372,0,0,0-4.364,1.859l7.238,17a2.372,2.372,0,0,0,2.176,1.442h.006a2.372,2.372,0,0,0,2.177-1.431l7.277-16.837A2.372,2.372,0,0,0,968,581.191Zm-25.149-.355a2.372,2.372,0,0,0-2.371,2.372v16.98a2.371,2.371,0,0,0,4.743,0v-16.98A2.372,2.372,0,0,0,942.855,580.836Zm60.6,8.831V575.93a2.372,2.372,0,0,0-4.744,0v5.719a9.652,9.652,0,0,0-14.584,8.315v3.467a9.66,9.66,0,0,0,14.964,8.084,2.369,2.369,0,0,0,4.363-1.28v-6.507c0-.1.015-.2.015-.3v-3.467C1003.466,589.864,1003.455,589.767,1003.452,589.667Zm-4.744,3.913a4.921,4.921,0,0,1-9.84-.149v-3.467a4.921,4.921,0,0,1,9.84-.149Zm-21.9-15.275a2.434,2.434,0,0,0,1.684-.7,2.372,2.372,0,0,0,.687-1.672,2.061,2.061,0,0,0-.047-.462,1.937,1.937,0,0,0-.131-.451,2.593,2.593,0,0,0-.213-.4,4.354,4.354,0,0,0-.3-.368,2.468,2.468,0,0,0-3.356,0,2.8,2.8,0,0,0-.3.368,2.593,2.593,0,0,0-.213.4,2.846,2.846,0,0,0-.142.451,2.994,2.994,0,0,0-.048.462,2.389,2.389,0,0,0,2.371,2.372Zm0,2.531a2.372,2.372,0,0,0-2.372,2.372v16.98a2.372,2.372,0,0,0,4.743,0v-16.98A2.372,2.372,0,0,0,976.811,580.836Z"
            transform="translate(-859.978 -568.35)"
            fill="#5e6c71"
        ></path>
        <path
            data-v-cdbe3ac4=""
            d="M878.615,606.509a36.689,36.689,0,0,0,12.831-9.671c-2.825-.728-2.89-4.495-1.109-7.939,1.806-3.494,3.953-7.6,2.842-11.661-.726-3.184-6.557-9.933-16.67-5.757-6.554,2.892-11.193,10.033-12,17.388a19.154,19.154,0,0,0,2.174,11.233C869.46,604.737,874.022,607.243,878.615,606.509Z"
            transform="translate(-851.327 -570.156)"
            fill="url(#a)"
        ></path>
        <path
            data-v-cdbe3ac4=""
            d="M889.085,598.38c-1,.35-3.274.561-6.6-5.074-1.991-3.083-3.923-6.166-6.676-8.644a17.209,17.209,0,0,0-8.358-4.283,16.42,16.42,0,0,0-3.256-.26c-8.994.156-16.433,8.057-15.029,17.208,2.154,14.047,19.472,13.573,25.93,11.188A36.42,36.42,0,0,0,889.085,598.38Z"
            transform="translate(-848.993 -571.665)"
            fill="url(#b)"
        ></path>
        <path
            data-v-cdbe3ac4=""
            d="M878.591,608.032l.034-.014a36.674,36.674,0,0,0,12.792-9.625h0c-1,.349-3.274.556-6.6-5.075-1.991-3.083-3.923-6.166-6.676-8.644a17.208,17.208,0,0,0-8.358-4.283c-.509-.093-1.022-.159-1.538-.2a23.183,23.183,0,0,0-3.736,10.2,19.153,19.153,0,0,0,2.174,11.233C869.456,606.248,874.005,608.753,878.591,608.032Z"
            transform="translate(-851.327 -571.675)"
            fill="url(#c)"
        ></path>
        <path
            data-v-cdbe3ac4=""
            d="M896.722,600.573c-.925-1.042-2.78-1-4.08.675-.024.028-.048.055-.073.082a35.618,35.618,0,0,1-20.485,12.075c-.479.093-.486.849.226.843,10.762-.559,20.174-6.552,23.607-9.741,1.477-1.2,1.657-2.977.805-3.935Z"
            transform="translate(-852.44 -574.658)"
            fill="#00c2d2"
        ></path>
    </svg>
</template>

<script>
export default {
    name: 'Logo',
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
