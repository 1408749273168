import { UserService, AuthenticationError } from '../../services/user.service'
import _ from 'lodash'
import { Toast } from '@/services/toast.service.js';

const state = {
    acl: localStorage.acl ? JSON.parse(localStorage.acl) : null
};

const getters = {
    acl: state => {
        return state.acl;
    }
};

const actions = {
    async getPermissions({commit}){
        try {
            const acl = await UserService.getUserPermissions();
            const roles = acl?.roles
            if (roles) {
                let allowed = false
                const roleNames = _.map(roles, 'name')
                roleNames.forEach((role) => {
                    if (role === 'Administrador' || role === 'kivid-suporte' || role === 'kivid-comercial') {
                        allowed = true
                    }
                })
                if (!allowed) {
                    await UserService.logout()
                    document.location.reload()
                }
            }
            commit('permissionSuccess', acl)
        } catch (e) {
            commit('loginError', {errorCode: e.errorCode, errorMessage: e.message || 'authentication error'})
            throw e
        }
    },
};

const mutations = {
    permissionSuccess(state,acl) {
        localStorage.removeItem('acl');
        const parsed = JSON.stringify(acl);
        state.acl = acl;
        localStorage.setItem('acl', parsed);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
