function page(path) {
    return () => import(/* webpackChunkName: '' */ `@/views/${path}`).then(m => m.default || m);
}

export default [
    {
        path: '/auth',
        name: 'auth',
        component: page('auth/AuthPortal.vue'),
        children: [
            {
                name: 'login',
                path: 'login',
                component: page('auth/Login.vue')
            },
            {
                name: 'signup',
                path: 'signup',
                component: page('auth/Signup.vue')
            }
        ]
    },
    {
        path: '/',
        name: 'home',
        component: page('home/Home.vue'),
        children: [
            {
                path: '/profile',
                name: 'profile',
                component: page('Profile.vue')
            }
        ],
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/resumo',
        name: 'resume',
        component: page('Resume.vue'),
        meta: {
            requiresAuth: true,
            roles: ['Administrador']
        }
    },
    {
        path: '/vendas',
        name: 'sales',
        component: page('sales/Sales.vue'),
        meta: {
            requiresAuth: true,
            roles: ['Administrador']
        }
    },
    {
        path: '/usuarios',
        name: 'users',
        component: page('Users.vue'),
        meta: {
            requiresAuth: true,
            roles: ['Administrador']
        }
    },
    {
        path: '/financeiro',
        name: 'finantial',
        component: page('financial/Finantial.vue'),
        meta: {
            requiresAuth: true,
            roles: ['Administrador']
        }
    },
    {
        path: '/operacional',
        name: 'operational',
        component: page('operational/Operational.vue'),
        children: [
            { path: '', redirect: { name: 'operational.users' } },
            {
                name: 'operational.users',
                path: 'usuarios',
                component: page('operational/Users.vue'),
                meta: {
                    requiresAuth: true,
                    roles: ['Administrador', 'kivid-suporte']
                }
            },
            {
                name: 'operational.user',
                path: 'usuarios/:id',
                component: page('operational/User.vue')
            },
            {
                name: 'operational.user.bank',
                path: 'usuarios/:id/dados-bancarios',
                component: page('operational/UserBank.vue')
            },
            {
              name: 'operational.logs',
              path: 'logs',
              component: page('operational/Logs.vue')
          },
        ],
        meta: {
            requiresAuth: true,
            roles: ['Administrador', 'kivid-suporte']
        }
    },
    {
        path: '/marketing',
        name: 'marketing',
        component: page('Marketing.vue'),
        meta: {
            requiresAuth: true,
            roles: ['Administrador']
        }
    },
    {
        path: '/network',
        name: 'network',
        component: page('network/Network.vue'),
        meta: {
            requiresAuth: true,
            roles: ['Administrador']
        }
    },
    {
        path: '*', // make sure this is last - it will match anything not matched above
        name: 'default',
        component: page('Home.vue')
    }
];
